import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Playground, Props } from 'docz';
import { Navigation, NavigationSection, NavigationDivider, Icon } from '@class101/ui';
import { PlayGroundContainer, ThemeProvider } from '../docsComponents';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "navigation"
    }}>{`Navigation`}</h1>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={Navigation} mdxType="Props" />
    <h2 {...{
      "id": "basic-usage"
    }}>{`Basic usage`}</h2>
    <Playground __position={1} __code={'<PlayGroundContainer>\n  <Navigation pathname=\"/components/navigation\">\n    <NavigationSection\n      items={[\n        { url: \'/\', label: \'홈\', icon: <Icon.Home /> },\n        { url: \'/inbox\', label: \'알림\', icon: <Icon.Bell /> },\n        { url: \'/dashboard\', label: \'대시보드\', icon: <Icon.Check /> },\n      ]}\n    />\n    <NavigationDivider />\n    <NavigationSection\n      title=\"주문 관리\"\n      action={{\n        onClick: () => alert(\'도움!\'),\n        icon: <Icon.Help />,\n      }}\n      items={[\n        {\n          label: \'상품 관리\',\n          icon: <Icon.Gift />,\n          subItems: [\n            { label: \'노출 관리\', url: \'/\' },\n            { label: \'재고 관리\', url: \'/components/navigation\', badge: \'2\' },\n            { label: \'템플릿 관리\', url: \'/\' },\n          ],\n        },\n        {\n          url: \'/components/navigation\',\n          label: \'배송 관리\',\n          icon: <Icon.ShippingTruck />,\n          badge: 32,\n        },\n      ]}\n    />\n  </Navigation>\n</PlayGroundContainer>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Navigation,
      NavigationSection,
      NavigationDivider,
      Icon,
      PlayGroundContainer,
      ThemeProvider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <PlayGroundContainer mdxType="PlayGroundContainer">
    <Navigation pathname="/components/navigation" mdxType="Navigation">
      <NavigationSection items={[{
            url: '/',
            label: '홈',
            icon: <Icon.Home />
          }, {
            url: '/inbox',
            label: '알림',
            icon: <Icon.Bell />
          }, {
            url: '/dashboard',
            label: '대시보드',
            icon: <Icon.Check />
          }]} mdxType="NavigationSection" />
      <NavigationDivider mdxType="NavigationDivider" />
      <NavigationSection title="주문 관리" action={{
            onClick: () => alert('도움!'),
            icon: <Icon.Help />
          }} items={[{
            label: '상품 관리',
            icon: <Icon.Gift />,
            subItems: [{
              label: '노출 관리',
              url: '/'
            }, {
              label: '재고 관리',
              url: '/components/navigation',
              badge: '2'
            }, {
              label: '템플릿 관리',
              url: '/'
            }]
          }, {
            url: '/components/navigation',
            label: '배송 관리',
            icon: <Icon.ShippingTruck />,
            badge: 32
          }]} mdxType="NavigationSection" />
    </Navigation>
  </PlayGroundContainer>
    </Playground>
    <h2 {...{
      "id": "external-link"
    }}>{`External Link`}</h2>
    <Playground __position={2} __code={'<PlayGroundContainer>\n  <Navigation pathname=\"/components/navigation\">\n    <NavigationSection\n      items={[\n        {\n          url: \'/components/navigation\',\n          external: true,\n          label: \'새 창 열기\',\n          icon: <Icon.Home />,\n        },\n      ]}\n    />\n    <NavigationDivider />\n    <NavigationSection\n      title=\"도움말\"\n      action={{\n        onClick: () => alert(\'도움!\'),\n        icon: <Icon.Help />,\n      }}\n      items={[\n        {\n          label: \'클래스101\',\n          icon: <Icon.Gift />,\n          subItems: [{ label: \'가기\', url: \'https://class101.net\' }],\n        },\n      ]}\n    />\n  </Navigation>\n</PlayGroundContainer>'} __scope={{
      props,
      DefaultLayout,
      Playground,
      Props,
      Navigation,
      NavigationSection,
      NavigationDivider,
      Icon,
      PlayGroundContainer,
      ThemeProvider,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  <PlayGroundContainer mdxType="PlayGroundContainer">
    <Navigation pathname="/components/navigation" mdxType="Navigation">
      <NavigationSection items={[{
            url: '/components/navigation',
            external: true,
            label: '새 창 열기',
            icon: <Icon.Home />
          }]} mdxType="NavigationSection" />
      <NavigationDivider mdxType="NavigationDivider" />
      <NavigationSection title="도움말" action={{
            onClick: () => alert('도움!'),
            icon: <Icon.Help />
          }} items={[{
            label: '클래스101',
            icon: <Icon.Gift />,
            subItems: [{
              label: '가기',
              url: 'https://class101.net'
            }]
          }]} mdxType="NavigationSection" />
    </Navigation>
  </PlayGroundContainer>
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      